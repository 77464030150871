@charset "utf-8";
@import 'node_modules/bulma/bulma';

$text-color: #000;
$primary-color: #2cdbad;
$secondary-color: #000c33;
$danger: red;


html {
  height: 100%;
}

body {
  min-height: 100%;
  font-style: normal;
  font-weight: 400;
  color: $text-color;
  margin: 0px;
  padding: 0px;
}

#app {
  // font-family: $family-sans-serif;
  font-family: 'Work Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $secondary-color;
  padding-bottom: 0;
  margin-bottom: 0;
  min-height: 100vh;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.flashAnimation {
  animation-name: flash;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h1 {
  font-family: 'Bebas Neue';
}
h2 {
  font-family: 'Bebas Neue';
}

.textarea {
  border: 5px solid $primary-color;
  width: 100%;
}

.title {
  color: $primary-color;
}

.text-primary-color {
  color: $primary-color;
}

.text-secondary-color {
  color: $secondary-color;
}

.text-danger-color {
  color: red;
}

.is-primary-background {
  background: $primary-color;
}

.is-secondary-background {
  background: $secondary-color;
}

.targetValue {
  background: $primary-color;
  color: #fff;
}

.alarmValue {
  background: red;
  color: #fff;
}

.ribbonTitle {
  font-family: 'Bebas Neue';
  background: $primary-color; 
  color: #fff;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  padding: 12px 0 6px 0;
  font-size: 20px;
  @media screen and (min-width: 900px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 40px;
  }
  line-height: 1;
  text-transform: uppercase;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #fff;
    height: 20px;
    width: 20px;
    @media screen and (min-width: 900px) {
      height: 35px;
      width: 35px;
    }
    @media screen and (min-width: 1200px) {
      height: 50px;
      width: 50px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    border-radius: 50%;
    background: #fff;
    height: 20px;
    width: 20px;
    @media screen and (min-width: 900px) {
      height: 35px;
      width: 35px;
    }
    @media screen and (min-width: 1200px) {
      height: 50px;
      width: 50px;
    }
  }
}

.hukkaBtn {
  font-size: 30px;
  line-height: 1;
  text-transform: uppercase;
  padding: 20px 20px;
  background: $secondary-color;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  &--disabled {
    cursor: default;
    background: rgba($secondary-color, 0.7);
  }
}

.removeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: $danger;
  font-size: 25px;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: red;
  }
}
.editBtn {
  position: absolute;
  top: 12px;
  right: 36px;
  color: $primary-color;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: $secondary-color;
  }
}

.container {
  padding: 60px 5%;
  width: 100%;
  max-width: 1760px;
}
.title .button {
  font-family: 'Bebas Neue';
  font-style: bold;
  font-weight: 700;
}

.button {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.levelRow {
  background-color: white;
  border-width: 2px;
  border-color: #dbdbdb;
  border-style: solid;
  padding: 0px;
}

.levelRow .button {
  text-transform: uppercase;
  font-size: 1rem;
  border-style: none;
  border-left-style: solid;
  border-left-width: 2px;
  border-color: #dbdbdb;
  line-height: 2rem;
  height: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0px;
}

.levelRowTitle {
  padding-left: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.hukkaselect {
  margin-bottom: 0.75rem;
  cursor: pointer;
  height: auto!important;
  min-height: 2.5em!important;
  &>div>div {
    border-width: 5px;
    border-color: $primary-color;
    cursor: pointer;
    &:hover {
      border-color: rgba($primary-color, 0.5);
    }
    &>div:first-of-type {
      padding: 2px 40px 2px 8px;
    }
    svg {
      color: red;
      z-index: 1;
    }
    &>div:last-of-type>div>svg {
      margin-left: -40px;
    }
  }
}

.hukkaselect select {
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
}

#hukka-logo {
  margin-top: 3rem;
  width: 100%;
  max-width: 800px;
}
#fullscreen-hukka-logo {
  position: absolute;
  width: 12%;
  bottom: 10px;
  right: 2%;
}

.modal-card-head {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.modal-card-foot {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* ### Navigation ### */
.mainNav {
  font-family: 'Bebas Neue';
  background: $secondary-color;
  display: block;
  width: 100%;
  padding: 10px 5% 5px 5%;
  z-index: 100;
  &__items {
    display: none;
    margin-top: 50px;
    @media screen and (min-width: 1000px) {
      width: 100%;
      margin: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
    }
    @media screen and (max-width: 999px) {
      &--open {
        display: block;
      }
    }
  }
  &__item {
    width: 100%;
    display: block;
    margin: 16px 0;
    @media screen and (min-width: 1000px) {
      width: auto;
      display: inline-block;
      margin: 0;
    }
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    @media screen and (min-width: 1400px) {
      font-size: 30px;
      line-height: 30px;
    }
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
  &__select {
    cursor: pointer;
    z-index: 100;
    position: relative;
    &>.mainNav__item {
      &:after {
        content: '\25BC';
      }
    }
    &__options {
      z-index: 100;
      display: none;
      &__item {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0 30px;
      }
    }
    &:hover {
      &>.mainNav__item {
        color: $primary-color;
      }
      .mainNav__select__options {
        display: inline-block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        background: $secondary-color;
      }
    }
  }
  &__burger {
    display: block;
    margin: 10px 0 20px 0;
    @media screen and (min-width: 1000px) {
      display: none;
    }
    &__link {
      display: block;
      height: 30px;
      position: relative;
      width: 40px;
      margin: auto;
      span {
        position: absolute;
        background-color: #fff;
        height: 6px;
        margin-top: 8px;
        margin-left: 0px;
        width: 100%;
        &:nth-child(1) {
          transform: translate(-50%, 0);
          top: 4px;
          margin-top: 0px;
          margin-left: 0px;
        }
        
        &:nth-child(2) {
          transform: translate(-50%, 0);
          top: 16px;
          margin-top: 0px;
          margin-left: 0px;
        }
        
        &:nth-child(3) {
          transform: translate(-50%, 0);
          top: 28px;
          margin-top: 0px;
          margin-left: 0px;
        }
      }
    }
  }

  .icon {
    i {
      font-size: 24px;
    }
  }
}



@media only screen and (max-width: 1023px) {
  .navbar-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .levelRow .button {
    margin: 1rem;
    border: solid;
  }
}
.taginput .taginput-container > .tag {
  padding: 1.2em;
  width: 100%;
  font-size: 1.2em;
}

#hukka-content {
  flex: 1;
}

.footer-image {
  width: 150px;
  margin: 0 auto;
}

#hukka-footer div {
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -150px;
  margin-bottom: -5px;
  height: 150px;
  width: 100px;
}
#hukka-footer div img {
  width: 100%;
  margin: 0px;
}
.is-green {
  background-color: #8ab51d;
  color: #fff;
  font-weight: bold;
}
.is-red {
  background-color: $danger;
  color: #fff;
  font-weight: bold;
}

.is-green:hover {
  opacity: 0.9;
  color: #fff;
}
.is-red:hover {
  opacity: 0.9;
  color: #fff;
}

.is-green:disabled {
  background-color: #8ab51d;
  opacity: 0.5;
  color: #2c3e50;
}
.is-red:disabled {
  background-color: $danger;
  opacity: 0.5;
  color: #2c3e50;
}
.is-input-middle {
  text-align: center;
}

.old_entry {
  color: rgba(0, 0, 0, 0.3);
}

.red-border {
  border: 2px solid $danger;
}

.hover-border {
  border-bottom: 1px solid transparent;
}

.hover-border:hover {
  border-bottom: 1px solid $primary-color;
}